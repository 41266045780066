import React, { memo, useMemo, useCallback } from 'react';
import useTracking from 'components/Globals/Analytics';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { SecondaryButton } from 'components/uiLibrary/LinkButton';

import { getProductionTitle } from 'utils/productions';
import { trackClickTickets } from 'utils/tracking';
import { createDate } from 'utils/date';
import { GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

export const getTicketsURL = (production, { prioritizeWebsiteURL = false, skipDateCheck = false } = {}) => {
  const firstTicketEntry = production?.tickets?.[0];
  const ticketWebsiteKey = prioritizeWebsiteURL ? 'website' : 'boxOfficeWebsite';
  const ticketWebsite = firstTicketEntry?.[ticketWebsiteKey];
  const productionMaxDate = createDate(production?.maxDate);
  const hasTickets =
    (createDate().isSame(productionMaxDate, 'day') || productionMaxDate.isAfter(createDate(), 'day')) &&
    ticketWebsite?.length > 0;

  if (skipDateCheck || hasTickets) {
    return ticketWebsite;
  }

  return null;
};

const TicketButton = ({ production, styles, size, hasLeftIcon = true, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const productionTitle = getProductionTitle(production);
  const ticketUrl = useMemo(() => getTicketsURL(production), [production]);

  const trackTickets = useCallback(() => {
    track.click(
      trackClickTickets({ 'Click URL': ticketUrl, production_id: production?.id }),
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  }, [track, ticketUrl, production, productionTitle]);

  if (!ticketUrl) {
    return null;
  }

  return (
    <SecondaryButton
      isLink
      external
      href={ticketUrl}
      rightIcon={<SpriteIcon icon="open_in_new" />}
      leftIcon={hasLeftIcon && <SpriteIcon icon="tickets" />}
      onClick={trackTickets}
      styles={styles}
      size={size}
      trackingData={{
        ...trackingData,
        subComponent: SUB_COMPONENTS.TICKET_CTA,
        meta: { ticketUrl, productionId: production?.id },
      }}
    >
      {t(`${TP}.FN_TICKETS`)}
    </SecondaryButton>
  );
};

export default memo(TicketButton);
